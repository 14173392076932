import LegalbirdIoModal from "../../Modal/LegalbirdIoModal";
import React, { useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import { convertToFloat, formValue } from "../../../services/formServiceFunctions";
import { getTemplates, paidTaskTemplateFields, updateFeeValidators } from "../../../services/paidTaskService";
import ValidatorForm from "../../Validator/ValidatorForm";
import moment from "moment";
import useForm from "../../../hooks/useForm";
import _ from "lodash";
import ValidatorSelect from "../../Validator/ValidatorSelect";
import { MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import PaidTaskFormFields from "../../CasePaidTask/PaidTaskFormFields";
import SubmitButton from "../../Button/SubmitButton";
import { useSnackbar } from "notistack";
import { getCaseLink, getProductData } from "../../../services/Product/ProductService";
import { requiredFieldDefault } from "../../../services/validationRules";
import { AbstractCase } from "../../../types/AbstractCase";
import { apiGet, apiPost, apiPut } from "../../../services/Api/apiCall";
import { PaidTask } from "../../../types/PaidTask";
import { BackofficeUser } from "../../../types/BackofficeUser";
import { useCurrentUser } from "../../../provider/CurrentUserProvider";
import { fillTemplate } from "../../../services/Template/templateService";
import { ExternalLawyerCapacity } from "../../../types/ExternalLawyerCapacity";

interface CreatePaidTaskProps {
  product: AbstractCase;
  open: boolean;
  handleClose: () => void;
  refreshPage: Function;
}

export default function CreatePaidTaskModal({ product, open, handleClose, refreshPage }: CreatePaidTaskProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [externalLawyersCapacities, setExternalLawyersCapacities] = useState<ExternalLawyerCapacity[] | null>(null);
  const [externalLawyerRecommendation, setExternalLawyerRecommendation] = useState<BackofficeUser | null>(null);
  const currentUser = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!open) {
      return;
    }
    apiGet("/services/external/capacities").then((capacitiesResponse) =>
      setExternalLawyersCapacities(capacitiesResponse)
    );
    apiGet("/services/external/recommendation", null, { backofficeCaseId: product.backofficeCase?.id }).then(
      (recommendationResponse) => {
        setExternalLawyerRecommendation(recommendationResponse);
        handleChange({
          target: {
            name: "requestedUser",
            value: recommendationResponse?.recommendation
              ? "backoffice_users/" + recommendationResponse.recommendation
              : "",
          },
        });
      }
    );
  }, [open, product]);

  const noLawyerAvailable = useMemo(() => {
    return !_.reduce(
      externalLawyersCapacities,
      (result, value) => {
        return value.isAvailable || result;
      },
      false
    );
  }, [externalLawyersCapacities]);

  const isExternalLawyerDisabled = (externalLawyerCapacity: ExternalLawyerCapacity) => {
    const chosenTemplate = _.find(templates, (template) => template.type === values.type);
    return !chosenTemplate?.getIsUserRequestable({ externalLawyerCapacity });
  };

  const onSubmit = async ({ values }: { [x: string]: any }) => {
    setIsLoading(true);
    let paidTask: PaidTask = await apiPost("paid_tasks", {
      subject: values.subject,
      description: values.description,
      definitionOfDone: values.definitionOfDone,
      reviewRemarks: values.reviewRemarks,
      backofficeCase: product.backofficeCase ? product.backofficeCase["@id"] : null,
      assignmentDeadline: moment(values.assignmentDeadline).format(),
      doneDeadline: moment(values.doneDeadline).format(),
      fee: convertToFloat(values.fee),
      type: values.type,
      creator: currentUser["@id"],
    });

    if (values.requestLawyer === "yes" && values.requestedUser) {
      await apiPut("paid_tasks", paidTask.id, {
        requestedUser: values.requestedUser,
        requestedUserDate: moment().format("DD.MM.YYYY"),
      });
    }

    enqueueSnackbar("Aufgabe erfolgreich ausgeschrieben", {
      variant: "custom",
      buttonType: "link",
      buttonLink: getCaseLink(product) + "/ausschreibung/" + paidTask.id,
      buttonTarget: "_self",
      buttonText: "Zur Aufgabe",
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    await refreshPage();
    handleClose();
  };

  const initialValues = {
    subject: "",
    description: "",
    definitionOfDone: "",
    reviewRemarks: "",
    assignmentDeadline: moment().add(5, "days"),
    doneDeadline: moment().add(10, "days"),
    fee: "",
    type: "",
    requestLawyer: "no",
    requestedUser: "",
  };

  const { values, errors, handleChange, handleSubmit, registerValidators, handleBlur, handleDateChange }: any = useForm(
    {
      initialValues,
      onSubmit,
    }
  );

  const templates = useMemo(() => {
    const productStages = getProductData(product.productClassName, "stageConstants");
    return _.filter(getTemplates(product), (template) => !template.isHidden({ product, productStages }));
  }, [product]);

  useEffect(() => {
    updateFeeValidators(registerValidators, values.type, product);
  }, [values.type]);

  const handleTypeChange = (event: SelectChangeEvent<unknown>) => {
    handleChange(event);
    const chosenTemplate = _.find(templates, (template) => template.type === event.target.value);
    _.forEach(chosenTemplate, (value: any, key: string) => {
      if (_.includes(paidTaskTemplateFields.basic, key)) {
        handleChange({
          target: {
            name: key,
            value: value,
          },
        });
      }
      if (_.includes(paidTaskTemplateFields.placeholderText, key)) {
        handleChange({
          target: {
            name: key,
            value: fillTemplate(value, product, {}),
          },
        });
      }
      if (_.includes(paidTaskTemplateFields.date, key)) {
        handleChange({
          target: {
            name: key,
            value: moment().add(value.amount, value.unit),
          },
        });
      }
    });
  };

  const formElementsEnabled = !!values.type;
  return (
    <LegalbirdIoModal
      handleClose={handleClose}
      open={open}
      title={"Neue Aufgabe ausschreiben"}
      submitButton={
        <SubmitButton
          fullWidth={false}
          variant={"contained"}
          type={"submit"}
          isLoading={isLoading}
          onClick={handleSubmit}
          disabled={!formElementsEnabled}
        >
          Aufgabe ausschreiben
        </SubmitButton>
      }
      actionsInfo={
        values.fee === "0" ? (
          <Typography textAlign={"center"}>Sind Sie sicher, dass Sie für 0€ ausschreiben möchten?</Typography>
        ) : (
          ""
        )
      }
    >
      <ValidatorForm onSubmit={handleSubmit}>
        <Grid container alignItems={"flex-start"} spacing={2}>
          <Grid item xs={12} md={6}>
            <ValidatorSelect
              label={"Typ"}
              name={"type"}
              value={formValue(values, "type")}
              onChange={handleTypeChange}
              onBlur={handleBlur}
              disabled={templates.length < 1}
              registerValidators={registerValidators}
              validators={requiredFieldDefault}
              error={!!errors["type"]}
              helperText={errors["type"]}
            >
              {_.map(templates, (template) => {
                return (
                  <MenuItem key={template.type} value={template.type}>
                    {template.label}
                  </MenuItem>
                );
              })}
            </ValidatorSelect>
          </Grid>
          <PaidTaskFormFields
            registerValidators={registerValidators}
            errors={errors}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            isDisabled={!formElementsEnabled}
            values={values}
          />
          <Grid item xs={12}>
            <ValidatorSelect
              label={"Anwalt anfragen"}
              name={"requestLawyer"}
              value={formValue(values, "requestLawyer")}
              onChange={handleChange}
              disabled={!formElementsEnabled || (noLawyerAvailable && false)} // TODO: undo this && false patch when refactoring how WBS lawyers can have infinite paid tasks
              helperText={
                noLawyerAvailable
                  ? "Alle Anwälte sind ausgelastet. Bitte Aufgabe frei ausschreiben, oder bei Zeitdruck selber erledigen."
                  : undefined
              }
            >
              <MenuItem key="no" value={"no"}>
                Nein
              </MenuItem>
              <MenuItem key="yes" value={"yes"}>
                Ja
              </MenuItem>
            </ValidatorSelect>
          </Grid>
          {values.requestLawyer === "yes" && (
            <Grid item xs={12}>
              <ValidatorSelect
                label={"Anfrage für Übernahme senden an"}
                name={"requestedUser"}
                value={formValue(values, "requestedUser")}
                onChange={handleChange}
                disabled={!formElementsEnabled}
              >
                {_.map(externalLawyersCapacities, (externalLawyerCapacity) => {
                  return (
                    <MenuItem
                      key={externalLawyerCapacity.backofficeUser.id}
                      value={"backoffice_users/" + externalLawyerCapacity.backofficeUser.id}
                      disabled={isExternalLawyerDisabled(externalLawyerCapacity)}
                    >
                      {externalLawyerCapacity.backofficeUser.person.fullname} ({externalLawyerCapacity.requestedTasks}{" "}
                      Anfragen | {externalLawyerCapacity.activeTasks} Aufgaben)
                    </MenuItem>
                  );
                })}
              </ValidatorSelect>
            </Grid>
          )}
        </Grid>
      </ValidatorForm>
    </LegalbirdIoModal>
  );
}
