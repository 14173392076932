import personFields from "./personFields";
import { caseReferenceNumberIsUnique, insurancePickerValidator, textFieldDefault } from "../../../validationRules";
import { AbstractCase } from "../../../../types/AbstractCase";

type BasicInsuranceFieldsProps = {
  path: string;
  product: AbstractCase;
  isHidden?: ({ product, values }: { product: AbstractCase; values?: Record<string, any> }) => boolean;
};

export default function basicInsuranceFields({ path, product, isHidden = () => false }: BasicInsuranceFieldsProps) {
  return [
    {
      type: "InsurancePicker",
      path: `${path}.insurance`,
      isHidden: isHidden,
      validators: insurancePickerValidator(product),
    },
    {
      type: "ValidatorTextField",
      path: `${path}.caseReferenceNumber`,
      isHidden: isHidden,
      validators: [
        ...textFieldDefault,
        {
          validator: caseReferenceNumberIsUnique,
          params: [product.insurance.caseReferenceNumber],
          message: "Diese Schadennummer existiert bereits in einem anderen Rechtsfall.",
        },
      ],
    },
    {
      type: "ValidatorTextField",
      path: `${path}.insurancePolicyNumber`,
      isHidden: isHidden,
    },
    {
      type: "ValidatorSelect",
      path: `${path}.policyHolderType`,
      isHidden: isHidden,
      options: () => [{ value: "me" }, { value: "familyMember" }],
    },
    ...personFields({
      path: `${path}.policyHolder`,
      isHidden: ({ product, values }) =>
        isHidden({ product }) ||
        !product.insurance.policyHolder ||
        values[product.productClassName].insurance.policyHolderType !== "familyMember",
    }),
  ];
}
