import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export const editorStateToHTML = (editorState: EditorState | null, emptyToNullConversion: boolean = false) => {
  if (!editorState) {
    return null;
  }

  const rawContentState = convertToRaw(editorState.getCurrentContent());
  const convertedContent = draftToHtml(rawContentState);

  if (emptyToNullConversion && !editorState.getCurrentContent().hasText()) {
    return null;
  }
  return convertedContent;
};

export const htmlToEditorState = (html: string | null | undefined) => {
  if (!html) {
    return EditorState.createEmpty();
  }
  const contentState = htmlToContentState(html);
  return EditorState.createWithContent(contentState);
};

export const htmlToBlockMap = (html: string) => {
  const contentState = htmlToContentState(html);
  let blockMap = contentState.getBlockMap();
  //the following is only here because convertFromRaw crashes if I insert blockmaps with the size 1
  //this results in a break line after single block textblocks
  //in convertFromDraftStateToRaw: block.getData() is null when a block of size 1 is inserted
  if (blockMap.size < 2) {
    const fixedContentState = htmlToContentState(html + "<div/>");
    blockMap = fixedContentState.getBlockMap();
  }
  return blockMap;
};

const htmlToContentState = (html: string) => {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  return ContentState.createFromBlockArray(contentBlocks, entityMap);
};
